<template>
  <ShortcutWrapper :nShortcutRequiredClaim="UserClaims.ContractManager" @n-shortcut="advanceDialog = true, openAdvanceForm({ actionType: 'open-form' })">
    <v-container fluid grid-list-xl>
      <material-card
      color="secondary"
      :title="$t('advances')"
      :text="$t('advanceDescription')"
      :elevation="0"
      :offsetTop="24"
      :offsetBottom="24"
      full-width
    >
      <v-card flat>
        <v-card-title>
          <v-row dense>
            <v-col cols="auto" v-if="Object.keys(advancesGroupedByEntity).length > 0">
              <EntitySelector
              :entities="Object.keys(advancesGroupedByEntity)"
              @entity-selected="entitySelected"
              />
          </v-col>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search',)"
              single-line
              clearable
              color="secondary"
            />
          </v-col>
          <v-col cols="auto" class="mx-n2">
            <v-tooltip bottom color="secondary">
              <template v-slot:activator="{ on }">
                <v-switch
                  color="secondary"
                  small
                  class="mt-5 ml-5"
                  v-model="dense"
                  v-on="on"
                ></v-switch>
              </template>
              <span class="white--text subtitle-1">{{ $t("denseView") }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="auto" class="mt-5">
            <Icon
            dataTestId="advance-all-button"
            :tooltipText="viewAllAdvances ? $t('viewByAccount') : $t('viewAll')"
            :small="false"
            @icon-clicked="viewAllAdvances = !viewAllAdvances"
            :icon="viewAllAdvances ? 'mdi-account' : 'mdi-view-list'"
            />
            <AdvancesFilter
              :filter="filter"
              @filter-applied="applyFilter"
            />
            <Icon
            dataTestId="advance-refresh-button"
            :tooltipText="$t('refresh')"
            :small="false"
            @icon-clicked="refreshAdvances"
            icon="mdi-refresh"
            />
            <Icon
            dataTestId="advance-download-button"
            :small="false"
            icon="mdi-file-delimited-outline"
            iconColor="secondary"
            :tooltipText="$t('downloadCSV')"
            @icon-clicked="downloadCSV"
            />
            <Icon
            dataTestId="advance-create-button"
            :tooltipText="$t('createAdvance')"
            :small="false"
            @icon-clicked="openAdvanceForm"
            icon="mdi-plus"
            :disabled="!userAssignedClaim(UserClaims.ContractManager)"
            />
          </v-col>
        </v-row>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-chip
                v-for="f in filtersView"
                :key="f.filterType"
                color="black"
                class="white--text mr-1"
                close
                @click:close="removeFilter(f)"
              >
                <span>{{f.text}}</span>
              </v-chip>
              <v-spacer/>
              <span style="color: black; font-size: 20px; margin-right: 70px">
                {{grandTotal}}
              </span>
            </v-row>
            <v-row dense align="center" justify="center" v-if="Object.keys(advancesGroupedByEntity).length === 0">
              <v-col cols="auto">
                <span class="title font-weight-bold">
                  {{$t('noAdvances')}}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-progress-linear indeterminate v-if="advancesLoading"></v-progress-linear>
                <AccountAdvancePanels
                  v-show="!viewAllAdvances"
                  :dense="dense"
                  :advances.sync="advancesGroupedByEntity[selectedEntity]"
                  :search.sync="search"
                  :asAtDate="asAtDate"
                  @advancesTotalUpdated="advancesTotalUpdated"
                  @refresh="refreshAdvances"
                />
                <AdvanceTable
                :search.sync="search"
                :dense="dense"
                :asAtDate="asAtDate"
                accountColumn
                v-show="viewAllAdvances"
                :propAdvances.sync="advancesGroupedByEntity[selectedEntity]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        </v-card>
        </material-card>
        <Dialog :stateId="dialogId" maxWidth="1000" @close="resetDialogs">
          <AdvanceForm
            v-if="advanceDialog"
            :noAccount="true"
            @advance-changed="closeForm()"
            @close-form="closeForm()"
          />
          <AdvanceModHistory
            v-if="advanceModificationsDialog.status"
            :propModifications="advanceModificationsDialog.modifications"
          />
        </Dialog>
      </v-container>
    </ShortcutWrapper>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
import { utcToLocalDate, localToUTC } from '@/utils/DateFormatter.js'
import { groupBy } from 'lodash'
import moment from 'moment'
import { saveAs } from 'file-saver'
import AdvanceHeaders from '@/headers/Advances.js'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { UserClaims } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { generateCsvString } from '@/utils/CSVUtility'
import { LocalStorageKeys } from '@/utils/constants'
export default {
  name: 'Advances',

  components: {
    AccountAdvancePanels: () => import('../../components/accounting/advances/AccountAdvancePanels.vue'),
    AdvanceTable: () => import('../../components/accounting/advances/AdvanceTable.vue'),
    AdvanceForm: () => import('@/components/account/advances/AdvanceForm.vue'),
    AdvanceModHistory: () => import('@/components/account/advances/AdvanceModHistory.vue'),
    Icon: () => import('../../components/helper/Icon.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue'),
    EntitySelector: () => import('@/components/accounting/EntitySelector.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    AdvancesFilter: () => import('@/components/accounting/advances/AdvancesFilter.vue')
  },

  data: () => ({
    dialogId: 'advances',
    asAtDate: undefined,
    limitToUnpaid: false,
    isValidDate: false,
    creating: false,
    editing: false,
    recovery: false,
    deleting: false,
    advanceDialog: false,
    viewAllAdvances: false,
    dense: true,
    search: '',
    selectedEntity: '',
    advancesGroupedByEntity: {},
    advancesTotal: 0,
    UserClaims,
    hideZeroBalance: undefined
  }),

  computed: {
    ...mapGetters('advances', ['allAdvances', 'advancesLoading', 'advanceModificationsDialog']),

    headers () {
      return AdvanceHeaders.allAdvanceHeaders(this.$i18n.locale)
    },
    advanceModDialogStatus () {
      return this.advanceModificationsDialog.status
    },
    grandTotal () {
      return `${this.$t('grandTotal')}: ${formatMoney(this.advancesTotal)}`
    },
    filter () {
      return { hideZeroBalance: this.hideZeroBalance, asAtDate: utcToLocalDate(this.asAtDate) }
    },
    filtersView () {
      const filter = []
      if (this.hideZeroBalance) {
        filter.push({
          filterType: 'hideZeroBalance',
          text: this.$t('hideAdvancesWithZeroBalance')
        })
      }
      if (!this.asAtIsToday) {
        filter.push({
          filterType: 'asAtDate',
          text: `${this.$t('showingDataAsOf')} ${moment(this.asAtDate).format('YYYY/MM/DD')}`
        })
      }
      return filter
    },

    asAtIsToday () {
      if (!this.asAtDate) return true
      const asAt = moment(this.asAtDate)
      return moment.utc().diff(asAt, 'days') === 0
    }
  },

  async created () {
    this.hideZeroBalance = localStorage.getItem(LocalStorageKeys.HIDE_ADVANCES_WITH_ZERO_BALANCE) === 'true'
    this.asAtDate = moment.utc().toISOString()
    await this.refreshAdvances()
    this.selectedEntity = this.allAdvances.length > 0 ? this.allAdvances[0].businessEntityName : ''
  },

  watch: {
    allAdvances () {
      if (this.allAdvances.length > 0) {
        this.advancesGroupedByEntity = groupBy(this.allAdvances, 'businessEntityName')
        if (!this.advancesGroupedByEntity[this.selectedEntity]) {
          this.selectedEntity = this.allAdvances[0].businessEntityName
        }
      } else {
        this.advancesGroupedByEntity = []
        this.selectedEntity = ''
      }
    },

    advanceModDialogStatus (val) {
      if (val) {
        this.advanceDialog = false
        this.openOrUpdateDialog({ id: this.dialogId, width: '45vw' })
      } else {
        this.closeDialogsAtOrAbove(this.dialogId)
      }
    }
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapMutations('advances', ['setAdvanceModificationsDialog']),
    ...mapActions('advances', ['fetchAdvances']),
    formatMoney,
    userAssignedClaim,

    async refreshAdvances () {
      await this.fetchAdvances({
        asAtDate: (!this.asAtIsToday) ? this.asAtDate : undefined,
        limitToUnpaid: this.limitToUnpaid,
        hideZeroBalance: this.hideZeroBalance
      })
    },

    getGLCodeText (advance) {
      let glString = advance.glCode
      if (advance.glOffset) {
        glString += `(${advance.glOffset})`
      }

      return glString.replaceAll('"', '""')
    },

    getRecoveryColumn (item) {
      const recoveryRate = item.recoveryRate
      const recoveryType = item.recoveryType

      const rate = recoveryType === 0 ? this.formatMoney(recoveryRate) : recoveryRate + '%'

      return rate
    },

    entitySelected (entity) {
      this.selectedEntity = entity
    },
    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.setAdvanceModificationsDialog({ modifications: undefined, status: false })
      this.advanceDialog = false
    },
    openAdvanceForm () {
      this.resetDialogs()
      this.advanceDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },
    closeForm () {
      this.resetDialogs()
      this.refreshAdvances()
    },
    advancesTotalUpdated (val) {
      this.advancesTotal = val
    },

    downloadCSV () {
      const csvString = generateCsvString(this.allAdvances, [
        { label: 'preIssued', value: advance => advance.preIssued ? this.$t('preIssued') : '' },
        { label: 'title', value: advance => advance.note ?? '' },
        { label: 'entity', value: advance => advance.payeeName },
        { label: 'tract', value: advance => advance.tract ?? this.$t('notAvailable') },
        { label: 'effective', value: advance => utcToLocalDate(advance.effectiveDate) },
        { label: 'amount', value: advance => `$${advance.amount}` },
        { label: 'balance', value: advance => `$${advance.balance}` },
        { label: 'recoveryRate', value: advance => `${advance.recoveryType === 0 ? '$' : ''}${advance.recoveryRate}` },
        { label: 'recoveryStartDate', value: advance => utcToLocalDate(advance.recoveryStartDate) },
        { label: 'activity', value: advance => advance.activityName },
        { label: 'glCodeAndOffset', value: advance => this.getGLCodeText(advance) }
      ], { emptyValue: this.$t('notAvailable') })
      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `Advances-${moment().format()}.csv`)
    },

    applyFilter (filter) {
      this.asAtDate = localToUTC(filter.asAtDate)
      this.hideZeroBalance = filter.hideZeroBalance
      this.refreshAdvances()
    },

    removeFilter (toRemove) {
      switch (toRemove.filterType) {
        case 'hideZeroBalance':
          this.hideZeroBalance = false
          localStorage.setItem(LocalStorageKeys.HIDE_ADVANCES_WITH_ZERO_BALANCE, 'false')
          break
        case 'asAtDate':
          this.asAtDate = moment.utc().toString()
          break
      }
      this.refreshAdvances()
    }
  }
}
</script>
